import React, { useState } from 'react';
import './style.css';
import {
  Alert,
  Button,
  Card,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { config } from '../../config';
import axios from 'axios';
import LoadingOverlay from '../../component/Loading';
import DesapediaLogo from '../../assets/images/Desapedia-01.png';

const DeleteAccount = () => {
  const [responData, setResponData] = useState({});
  const [modal, setModal] = useState(false);
  const [form, setForm] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    message: '',
  });

  const [visibleError, setVisibleError] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDismiss = () => setVisibleError(false);

  const toggle = () => setModal(!modal);

  const toggleError = () => setModalError(!modalError);

  const toggleSuccess = () => setModalSuccess(!modalSuccess);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Mengupdate state form menggunakan spread operator dan memodifikasi properti yang sesuai
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleConfirm = (event) => {
    event.preventDefault();

    setVisibleError(false);
    toggle();
    setErrors({
      ...errors,
      message: '',
    });
  };

  const onSubmit = async () => {
    toggle();
    setLoading(true);
    try {
      const dataToSend = {
        ...form,
        // createdBy: "User Landing Page",
        // updatedBy: "User Landing Page",
      };

      const headers = {
        'Content-Type': 'application/json',
        apikey: config.API_KEY,
      };

      const response = await axios.post(
        `${config.BASE_URL_DRIVER}auth/deleteAccount`,
        dataToSend,
        { headers }
      );

      if (response.status === 200) {
        setLoading(false);
        toggleSuccess();
        setResponData(response.data);
        console.log('response: ', response.data);
      }
    } catch (error) {
      setModalError(true);
      setLoading(false);
      setResponData(error.response.data);
      console.log(error.response.data);
    }
  };

  return (
    <div className='separators'>
      <div className='containers'>
        {loading && <LoadingOverlay />}
        {/* modal success */}
        <Modal isOpen={modalSuccess} toggle={toggleSuccess} centered>
          <ModalBody>
            <Alert>
              <h4 className='alert-heading'>Hapus Akun Sukses!</h4>
              <p>{responData.message}</p>
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ background: '#FF5C30' }}
              size='sm'
              onClick={() => {
                setModalSuccess(false);
                window.location.href = '/';
              }}
            >
              Tutup
            </Button>{' '}
          </ModalFooter>
        </Modal>
        {/* modal error */}
        <Modal isOpen={modalError} toggle={toggleError} centered>
          <ModalBody>
            <p>{responData.message}</p>
          </ModalBody>
          <ModalFooter style={{ border: 'none' }}>
            <Button
              style={{ background: '#FF5C30' }}
              size='sm'
              onClick={() => setModalError(false)}
            >
              Tutup
            </Button>{' '}
          </ModalFooter>
        </Modal>
        {/* modal confirm */}
        <Modal isOpen={modal} toggle={toggle} centered>
          <ModalBody>Apakah Anda yakin ingin menghapus akun ini?</ModalBody>
          <ModalFooter style={{ border: 'none' }}>
            <Button
              style={{ background: '#FF5C30' }}
              size='sm'
              onClick={onSubmit}
            >
              Iya
            </Button>{' '}
            <Button style={{ background: 'gray' }} size='sm' onClick={toggle}>
              Batal
            </Button>
          </ModalFooter>
        </Modal>

        {/* <div
          style={{
            borderBottom: '1px solid black',
            paddingTop: '16px',
          }}
        >
          <p>Desapedia</p>
        </div> */}
        {/* <Card
          style={{
            width: '100%',
          }}
        > */}

        <div style={{ textAlign: 'center', margin: '20px 50px 60px' }}>
          <div style={{ marginBottom: 40 }}>
            <img width={200} src={DesapediaLogo} alt='logo' />
          </div>
          <h5 style={{ marginBottom: '60px' }}>Permintaan Hapus Akun</h5>
          <p>
            Untuk melakukan permintaan hapus akun, silahkan anda mengisi detail
            akun anda
          </p>
        </div>
        <form className='form' action='' onSubmit={handleConfirm}>
          <div className='form-username'>
            <div>
              <label htmlFor='nama'>Username</label>
            </div>
            <div>
              <input
                style={{
                  padding: '4px',
                }}
                name='username'
                type='text'
                id='username'
                value={form.username}
                onChange={handleInputChange}
                // placeholder="Isi Username Anda"
              />
            </div>
          </div>
          <div className='form-password'>
            <div>
              <label htmlFor='asal'>Password</label>
            </div>
            <div>
              <input
                style={{
                  padding: '4px',
                }}
                name='password'
                id='password'
                type='password'
                value={form.password}
                onChange={handleInputChange}
                // placeholder=""
              />
            </div>
          </div>
          <p
            style={{
              fontSize: '10px',
              fontStyle: 'italic',
              color: 'red',
              marginTop: '10px',
              textAlign: 'center',
            }}
          >
            *Perhatian! Akun yang telah dihapus tidak dapat di kembalikan
            kembali{' '}
          </p>
          <input
            className='form-submit'
            type='submit'
            value='SUBMIT'
            disabled={!form.username || !form.password}
          />
        </form>
        {/* </Card> */}
      </div>
    </div>
  );
};

export default DeleteAccount;
