import React from 'react';
import './style.css';
import { Spinner } from 'reactstrap';
const LoadingOverlay = () => {
  return (
    <>
      <div
        className='loading-overlay'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          padding: 0,
          margin: 0,
          top: 0,
          left: 0,
          zIndex: 9999,
        }}
      >
        <Spinner style={{ margin: 'auto', color: 'white', zIndex: 999 }}>
          Loading...
        </Spinner>
      </div>
    </>
  );
};

export default LoadingOverlay;
