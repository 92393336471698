import React from "react";
import "./style.css";
const Policy = () => {
  const textLinkStyle = {
    color: "blue",
    cursor: "pointer",
    textDecoration: "underline",
  };

  return (
    <div style={{ margin: 10 }}>
      <h1 style={{ fontSize: 32 }}>KEBIJAKAN PRIVASI UNTUK APLIKASI MOBILE</h1>
      <h2 style={{ fontSize: 24 }}>
        /privacy mobile
        <br />
        DESAPEDIA.APP
      </h2>

      <p style={{ fontWeight: "bold" }}>
        <span style={{ fontStyle: "italic" }}>Last updated: Mei, 11, 2023</span>
        <br />
        Terakhir diperbaharui: 11 Mei 2023
      </p>
      <br />

      <p>
        DESAPEDIA mengembangkan aplikasi mobile sebagai langkah terobosan
        digital dalam kemudahan untuk mendaftar menjadi anggota partai, aplikasi
        ini gratis dan juag menyajikan berbagai informasi seputar kepartaian.
        Layanan ini disediakan tanpa biaya dan ditujukan untuk digunakan apa
        adanya. Halaman ini digunakan untuk memberi tahu pengunjung mengenai
        kebijakan kami dengan pengumpulan, penggunaan, dan pengungkapan
        Informasi Pribadi, jika ada yang memutuskan untuk menggunakan daftar
        sebagai anggota kami.
      </p>

      <p>
        Jika Anda memilih untuk menggunakan fitur kami, maka Anda setuju untuk
        pengumpulan dan penggunaan informasi sehubungan dengan kebijakan ini.
        Informasi Pribadi yang kami kumpulkan digunakan untuk menyediakan dan
        meningkatkan Layanan. Kami tidak akan menggunakan atau membagikan
        informasi Anda dengan siapa pun kecuali seperti yang dijelaskan dalam
        Kebijakan Privasi ini.
      </p>

      <p>
        Istilah yang digunakan dalam Kebijakan Privasi ini memiliki arti yang
        sama seperti dalam Syarat dan Ketentuan kami, yang dapat diakses di PU
        kecuali ditentukan lain dalam Kebijakan Privasi ini.
      </p>

      <p style={{ fontStyle: "italic" }}>
        DESAPEDIA developed a mobile application as a digital breakthrough step
        in the ease of registering to become a party member, this application is
        free and also presents various information about the party. This service
        is provided at no cost and is intended for use as is. This page is used
        to notify visitors of our policies with the collection, use and
        disclosure of Personal Information, if anyone decides to use the list as
        a member of us
      </p>

      <p style={{ fontStyle: "italic" }}>
        If you choose to use our feature, then you agree to the collection and
        use of information in relation to this policy. The Personal Information
        that we collect is used for providing and improving the Service. We will
        not use or share your information with anyone except as described in
        this Privacy Policy.
      </p>

      <p style={{ fontStyle: "italic" }}>
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, which are accessible at PU unless otherwise
        defined in this Privacy Policy.
      </p>

      <p>
        <span style={{ fontWeight: "bold" }}>
          PENGUMPULAN DAN PENGGUNAAN INFORMASI
        </span>
        <br />
        Untuk pengalaman yang lebih baik, saat menggunakan aplikasi kami, kami
        mungkin meminta Anda untuk memberi kami identifikasi pribadi tertentu,
        informasi file, termasuk namun tidak terbatas pada Aplikasi Moblie
        Partai Ummat. Informasi yang kami minta akan disimpan oleh kami dan
        digunakan seperti yang dijelaskan dalam kebijakan privasi ini.
      </p>

      <p>
        Aplikasi ini menggunakan aplikasi pihak ketiga yang dapat mengumpulkan
        informasi yang digunakan untuk mengidentifikasi Anda. Tautkan ke
        kebijakan privasi penyedia aplikasi pihak ketiga yang digunakan oleh
        aplikasi.{" "}
        <a
          style={textLinkStyle}
          href="https://www.google.com/policies/privacy/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Layanan Google Play
        </a>{" "}
        |{" "}
        <a
          style={textLinkStyle}
          href="https://www.apple.com/privacy"
          target="_blank"
          rel="noreferrer noopener"
        >
          Layanan Apple Store
        </a>
      </p>

      <p style={{ fontStyle: "italic" }}>
        <strong> Information Collection and Use</strong>
        <br />
        For a better experience, while using our Service, we may require you to
        provide us with certain personally identification, file information,
        including but not limited to DESAPEDIA’s Mobile App. The information
        that we request will be retained by us and used as described in this
        privacy policy.
      </p>

      <p style={{ fontStyle: "italic" }}>
        The app does use third-party services that may collect information used
        to identify you. Link to the privacy policy of third-party service
        providers used by the app.{" "}
        <a
          style={textLinkStyle}
          href="https://www.google.com/policies/privacy/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Google Play Services
        </a>{" "}
        |{" "}
        <a
          style={textLinkStyle}
          href="https://www.apple.com/privacy"
          target="_blank"
          rel="noreferrer noopener"
        >
          Apple Store Services
        </a>
      </p>
      <br />

      <p>
        <span style={{ fontWeight: "bold" }}>KEAMANAN</span>
        <br />
        Kami menghargai kepercayaan Anda dalam memberikan Informasi Pribadi Anda
        kepada kami, sehingga kami berusaha untuk menggunakan cara yang dapat
        diterima secara komersial untuk melindunginya. Tetapi ingat bahwa tidak
        ada metode transmisi melalui internet, atau metode penyimpanan
        elektronik yang 100% aman dan dapat diandalkan, dan kami tidak dapat
        menjamin keamanannya yang terlarut.
      </p>

      <p style={{ fontStyle: "italic" }}>
        <strong>Security</strong>
        <br /> We value your trust in providing us your Personal Information,
        thus we are striving to use commercially acceptable means of protecting
        it. But remember that no method of transmission over the internet, or
        method of electronic storage is 100% secure and reliable, and we cannot
        guarantee its solute security.
      </p>
      <br />

      <p>
        <span style={{ fontWeight: "bold" }}>TAUTAN KE SITUS LAIN</span>
        <br />
        Layanan ini mungkin berisi tautan ke situs lain. Jika Anda mengklik
        tautan pihak ketiga, Anda akan diarahkan ke situs itu. Perhatikan bahwa
        situs eksternal ini tidak dioperasikan oleh kami. Oleh karena itu, kami
        sangat menyarankan Anda untuk meninjau Kebijakan Privasi situs web ini.
        Kami tidak memiliki kendali atas dan tidak bertanggung jawab atas
        konten, kebijakan privasi, atau praktik situs atau layanan pihak ketiga
        mana pun.
      </p>

      <p style={{ fontStyle: "italic" }}>
        <strong>Links to Other Sites</strong>
        <br />
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>
      <br />

      <p>
        <span style={{ fontWeight: "bold" }}>PRIVASI ANAK-ANAK</span>
        <br />
        Layanan ini tidak ditujukan kepada siapa pun yang berusia di bawah 17
        tahun. Kami tidak secara sadar mengumpulkan informasi yang
        diidentifikasi secara pribadi dari anak-anak di bawah 17 tahun. Dalam
        kasus kami menemukan bahwa seorang anak di bawah 13 tahun telah memberi
        kami informasi pribadi, kami segera menghapus ini dari server kami. Jika
        Anda adalah orang tua atau wali dan Anda sadar bahwa anak Anda telah
        memberi kami informasi pribadi, silakan hubungi kami sehingga kami akan
        dapat melakukan tindakan yang diperlukan.
      </p>

      <p style={{ fontStyle: "italic" }}>
        <strong>Children’s Privacy</strong>
        <br />
        These Services do not address anyone under the age of 17. We do not
        knowingly collect personally identified information from children under
        17 years of age. In the case we discover that a child under 13 has
        provided us with personal information, we immediately delete this from
        our servers. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact us so
        that we will be le to do the necessary actions.
      </p>
      <br />

      <p>
        <span style={{ fontWeight: "bold" }}>
          PERUBAHAN PADA KEBIJAKAN PRIVASI INI
        </span>
        <br />
        Kami dapat memperbarui Kebijakan Privasi kami dari waktu ke waktu.
        Dengan demikian, Anda disarankan untuk meninjau halaman ini secara
        berkala untuk setiap perubahan. Kami akan memberi tahu Anda tentang
        perubahan apa pun dengan memposting Kebijakan Privasi baru di halaman
        ini.
      </p>

      <p>
        Kebijakan ini berlaku efektif pada tanggal <strong>11 Mei 2023.</strong>
      </p>

      <p style={{ fontStyle: "italic" }}>
        <strong>Changes to This Privacy Policy</strong>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page.
      </p>

      <p>
        This policy is effective as of <strong>Mei 11, 2023</strong>
      </p>
    </div>
  );
};

export default Policy;
