const config = {
  BASE_URL:
    process.env.REACT_APP_ISDEV === "true"
      ? "https://devapigateway.desapedia.co.id/v1/main/"
      : "https://apigateway.desapedia.co.id/v1/main/",

  BASE_URL_DRIVER:
    process.env.REACT_APP_ISDEV === "true"
      ? "https://simpeldesapi.loginusa.id/ojoldesapi/"
      : "https://simpeldesapi.loginusa.id/ojoldesapi/",

  BASE_URL_IMAGE:
    process.env.REACT_APP_ISDEV === "true"
      ? "https://simpeldesapi.loginusa.id/fileapi/"
      : "https://api.desapedia.co.id/fileapi/",

  API_KEY: `${process.env.REACT_APP_DESAPEDIA_TOKEN_APIKEY}`,
};

export { config };
