const dataArtikel = [
  {
    id: 1,
    title: "Title 1",
    creator: "Admin 1",
    tanggal: "21 Agustus 2023",
    content: [
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae nobis sint voluptates accusamus ut. Nulla optio consectetur accusantium non repudiandae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum quo ab quos Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eligendi esse quam distinctio aliquid!",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis aperiam consequuntur maiores quod dolorem quos quis illo, recusandae nostrum ut quaerat quo molestias error rerum quae. Eius, earum atque ea repellat sed modi est dicta?",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eos tempora, dicta ratione voluptatem quaerat aperiam, velit distinctio accusantium obcaecati ducimus natus labore? Eveniet velit iure perferendis dolores possimus nemo illo, ex impedit laboriosam esse.",
    ],
    gambar: "https://picsum.photos/300/200",
  },
  {
    id: 2,
    title: "Title 2",
    creator: "Admin 2",
    tanggal: "22 Agustus 2023",
    content: [
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae nobis sint voluptates accusamus ut. Nulla optio consectetur accusantium non repudiandae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum quo ab quos Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eligendi esse quam distinctio aliquid!",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis aperiam consequuntur maiores quod dolorem quos quis illo, recusandae nostrum ut quaerat quo molestias error rerum quae. Eius, earum atque ea repellat sed modi est dicta?",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eos tempora, dicta ratione voluptatem quaerat aperiam, velit distinctio accusantium obcaecati ducimus natus labore? Eveniet velit iure perferendis dolores possimus nemo illo, ex impedit laboriosam esse.",
    ],
    gambar: "https://picsum.photos/300/200",
  },
  {
    id: 3,
    title: "Title 3",
    creator: "Admin 3",
    tanggal: "23 Agustus 2023",
    content: [
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae nobis sint voluptates accusamus ut. Nulla optio consectetur accusantium non repudiandae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum quo ab quos Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eligendi esse quam distinctio aliquid!",
      "https://picsum.photos/300/200",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis aperiam consequuntur maiores quod dolorem quos quis illo, recusandae nostrum ut quaerat quo molestias error rerum quae. Eius, earum atque ea repellat sed modi est dicta?",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eos tempora, dicta ratione voluptatem quaerat aperiam, velit distinctio accusantium obcaecati ducimus natus labore? Eveniet velit iure perferendis dolores possimus nemo illo, ex impedit laboriosam esse.",
    ],
    gambar: "https://picsum.photos/300/200",
  },
  {
    id: 4,
    title: "Title 4",
    creator: "Admin 4",
    tanggal: "24 Agustus 2023",
    content: [
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae nobis sint voluptates accusamus ut. Nulla optio consectetur accusantium non repudiandae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum quo ab quos Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eligendi esse quam distinctio aliquid!",
      "https://picsum.photos/300/200",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eos tempora, dicta ratione voluptatem quaerat aperiam, velit distinctio accusantium obcaecati ducimus natus labore? Eveniet velit iure perferendis dolores possimus nemo illo, ex impedit laboriosam esse.",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eos tempora, dicta ratione voluptatem quaerat aperiam, velit distinctio accusantium obcaecati ducimus natus labore? Eveniet velit iure perferendis dolores possimus nemo illo, ex impedit laboriosam esse.",
      "https://picsum.photos/300/200",
    ],
    gambar: "https://picsum.photos/300/200",
  },
  {
    id: 5,
    title: "Title 5",
    creator: "Admin 5",
    tanggal: "25 Agustus 2023",
    content: [
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae nobis sint voluptates accusamus ut. Nulla optio consectetur accusantium non repudiandae.",
      "https://picsum.photos/300/200",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum quo ab quos Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eligendi esse quam distinctio aliquid!",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis aperiam consequuntur maiores quod dolorem quos quis illo, recusandae nostrum ut quaerat quo molestias error rerum quae. Eius, earum atque ea repellat sed modi est dicta?",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eos tempora, dicta ratione voluptatem quaerat aperiam, velit distinctio accusantium obcaecati ducimus natus labore? Eveniet velit iure perferendis dolores possimus nemo illo, ex impedit laboriosam esse.",
      "https://picsum.photos/300/200",
    ],
    gambar: "https://picsum.photos/300/200",
  },
  {
    id: 6,
    title: "Title 6",
    creator: "Admin 6",
    tanggal: "26 Agustus 2023",
    content: [
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae nobis sint voluptates accusamus ut. Nulla optio consectetur accusantium non repudiandae.",
      "https://picsum.photos/300/200",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum quo ab quos Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eligendi esse quam distinctio aliquid!",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis aperiam consequuntur maiores quod dolorem quos quis illo, recusandae nostrum ut quaerat quo molestias error rerum quae. Eius, earum atque ea repellat sed modi est dicta?",
      "https://picsum.photos/300/200",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eos tempora, dicta ratione voluptatem quaerat aperiam, velit distinctio accusantium obcaecati ducimus natus labore? Eveniet velit iure perferendis dolores possimus nemo illo, ex impedit laboriosam esse.",
    ],
    gambar: "https://picsum.photos/300/200",
  },
  {
    id: 7,
    title: "Title 7",
    creator: "Admin 7",
    tanggal: "27 Agustus 2023",
    content: [
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae nobis sint voluptates accusamus ut. Nulla optio consectetur accusantium non repudiandae.",
      "https://picsum.photos/300/200",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum quo ab quos Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eligendi esse quam distinctio aliquid!",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis aperiam consequuntur maiores quod dolorem quos quis illo, recusandae nostrum ut quaerat quo molestias error rerum quae. Eius, earum atque ea repellat sed modi est dicta?",
      "https://picsum.photos/300/200",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eos tempora, dicta ratione voluptatem quaerat aperiam, velit distinctio accusantium obcaecati ducimus natus labore? Eveniet velit iure perferendis dolores possimus nemo illo, ex impedit laboriosam esse.",
    ],
    gambar: "https://picsum.photos/300/200",
  },
  {
    id: 8,
    title: "Title 8",
    creator: "Admin 8",
    tanggal: "28 Agustus 2023",
    content: [
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae nobis sint voluptates accusamus ut. Nulla optio consectetur accusantium non repudiandae.",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum quo ab quos Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eligendi esse quam distinctio aliquid!",
      "https://picsum.photos/300/200",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis aperiam consequuntur maiores quod dolorem quos quis illo, recusandae nostrum ut quaerat quo molestias error rerum quae. Eius, earum atque ea repellat sed modi est dicta?",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eos tempora, dicta ratione voluptatem quaerat aperiam, velit distinctio accusantium obcaecati ducimus natus labore? Eveniet velit iure perferendis dolores possimus nemo illo, ex impedit laboriosam esse.",
      "https://picsum.photos/300/200",
    ],
    gambar: "https://picsum.photos/300/200",
  },
];

export default dataArtikel;
